import {
  distanceBetweenLocations,
  bearingBetweenLocations,
  createCartesianPolygonRepresentation,
  convertCartesianPointsToGeographicLocations,
  applyPaddingInCartesianPolygon,
  checkCartesianPolygonExists,
  getMissionPlannerCandidatesParameters,
  findMissionPlannerMesh,
  findMissionPlannerRoutes,
  getMeshLinesScore,
  getMissionRouteLinearDistanceScore,
  getMissionRouteAmountOfWaypointsScore,
  getMissionRouteGeneralScore,
  planArea,
  convertCartesianRouteToGeographicRoute,
} from "./impl";

export type {
  Location,
  CartesianPoint,
  CartesianLine,
  CartesianPolygonVertex,
  CartesianPolygonRepresentation,
  CartesianPlannerRoutePoint,
  MissionPlannerRoute,
  MissionPlannerCandidateParameters,
  MeshLine,
  CartesianMissionPlannerRoute,
  PlannerRoutePoint,
} from "./types";

export const MissionPlanner = {
  distanceBetweenLocations,
  bearingBetweenLocations,
  createCartesianPolygonRepresentation,
  convertCartesianPointsToGeographicLocations,
  applyPaddingInCartesianPolygon,
  checkCartesianPolygonExists,
  getMissionPlannerCandidatesParameters,
  findMissionPlannerMesh,
  findMissionPlannerRoutes,
  getMeshLinesScore,
  getMissionRouteLinearDistanceScore,
  getMissionRouteAmountOfWaypointsScore,
  getMissionRouteGeneralScore,
  convertCartesianRouteToGeographicRoute,
  planArea,
};
